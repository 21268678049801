<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- <c-card title="설비 기본정보" :collapsed="true" class="cardClassDetailInfo">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="설비선택" icon="add" @btnClicked="add"/>
            <c-btn
              v-if="editable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="equipInfo"
              :mappingType="saveType"
              label="저장"
              icon="save"
              @beforeAction="saveInfo"
              @btnCallback="saveCallback"
            />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div v-if="editable && equipInfo.equipmentTypeCd" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="설비유형" :value="equipInfo.equipmentTypeNm"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.equipmentCd" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="설비코드" :value="equipInfo.equipmentCd"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.equipmentName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="설비명" :value="equipInfo.equipmentName"></c-label-text>
          </div>
        </template>
      </c-card> -->
      <q-form ref="editForm">
        <c-card title="유해위험기계기구 기본정보" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn
                v-if="editable"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="equipInfo"
                :mappingType="saveType"
                label="저장"
                icon="save"
                @beforeAction="saveInfo"
                @btnCallback="saveCallback"
              />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-text
                :afterIcon="[
                  { name: 'search', click: true, callbackName: 'add', color: 'teal' },
                  { name: 'close', click: true, callbackName: 'remove', color: 'red' }
                ]"
                :editable="editable"
                label="설비"
                :disabled="true"
                :search="true"
                name="equipmentCd"
                @add="add"
                @remove="remove"
                v-model="equipInfo.equipmentCd"
              >
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-text
                required
                :editable="editable"
                label="기계명"
                name="machineryName"
                v-model="equipInfo.machineryName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-select
                required
                type="edit"
                codeGroupCd="HHM_TYPE_CD"
                itemText="codeName"
                itemValue="code"
                name="machineryClassification"
                label="기계분류"
                v-model="equipInfo.machineryClassification"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-text
                required
                :editable="editable"
                label="itemNo"
                name="itemNo"
                v-model="equipInfo.itemNo">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-dept type="edit" required label="담당부서" name="departmentDeptCd" v-model="equipInfo.departmentDeptCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-field
                required
                :editable="editable"
                label="담당자"
                type="user"
                name="departmentUserId"
                v-model="equipInfo.departmentUserId">
              </c-field>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-datepicker
                required
                type="month"
                :editable="editable"
                label="설치년월"
                name="emplacementMonth"
                v-model="equipInfo.emplacementMonth">
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-datepicker
                :editable="editable"
                type="month"
                label="철거년월"
                name="demolitionMonth"
                v-model="equipInfo.demolitionMonth"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-text
                :editable="editable"
                label="합격번호"
                name="passNumber"
                v-model="equipInfo.passNumber">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-text
                :editable="editable"
                label="제조사"
                name="manufacturer"
                v-model="equipInfo.manufacturer">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-text
                :editable="editable"
                label="설치장소"
                name="emplacementPlace"
                v-model="equipInfo.emplacementPlace">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-text
                :editable="editable"
                label="EWR NO."
                name="ewrNo"
                v-model="equipInfo.ewrNo">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-text
                :editable="editable"
                label="설계근거(정격하중)"
                name="esignBasisRatedLoad"
                v-model="equipInfo.esignBasisRatedLoad">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-text
                :editable="editable"
                label="설계근거(종류)"
                name="esignBasisKind"
                v-model="equipInfo.esignBasisKind">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-text
                :required="true"
                :editable="editable"
                :disabled="disabled"
                label="수량"
                name="amount"
                type="number"
                v-model="equipInfo.amount">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-plant
                required
                :editable="editable"
                type="edit"
                name="plantCd"
                v-model="equipInfo.plantCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-text
                :editable="editable"
                label="비고"
                name="remark"
                v-model="equipInfo.remark">
              </c-text>
            </div>
          </template>
        </c-card>
        <c-card class="cardClassDetailForm" title="검사정보">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-select
                required
                type="edit"
                codeGroupCd="HAZARD_GRADE_CD"
                itemText="codeName"
                itemValue="code"
                name="gradeCd"
                label="등급"
                v-model="equipInfo.gradeCd"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-select
                required
                :comboItems="targetCdItems"
                type="edit"
                itemText="codeName"
                itemValue="code"
                :editable="editable"
                label="검사대상 여부"
                name="inspectionSubjectFlag"
                v-model="equipInfo.inspectionSubjectFlag">
              </c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-select
                type="edit"
                :disabled="!(equipInfo.inspectionSubjectFlag === 'Y')"
                codeGroupCd="MDM_CHECK_KIND_CD"
                itemText="codeName"
                itemValue="code"
                :editable="editable"
                label="검사종류"
                name="inspectionKindCd"
                v-model="equipInfo.inspectionKindCd">
              </c-select>
            </div>
            <!-- HAZARD_CYCLE_CD -->
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-select
                :editable="editable"
                :disabled="!(equipInfo.inspectionSubjectFlag === 'Y')"
                type="edit"
                codeGroupCd="MDM_EQUIP_CYCLE_CD"
                itemText="codeName"
                itemValue="code"
                label="검사주기"
                name="inspectionCycle"
                v-model="equipInfo.inspectionCycle">
              </c-select>
            </div>
            <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-select
                :editable="editable"
                type="edit"
                codeGroupCd="HAZARD_RESULT_CD"
                itemText="codeName"
                itemValue="code"
                label="검사결과"
                :disabled="true"
                name="resultCd"
                v-model="equipInfo.resultCd">
              </c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-datepicker
                :editable="editable"
                label="최근검사일자"
                :disabled="true"
                name="lastInspectionDate"
                v-model="equipInfo.lastInspectionDate">
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-datepicker
                :editable="editable"
                label="차기검사일자"
                :disabled="true"
                name="nextInspectionDate"
                v-model="equipInfo.nextInspectionDate">
              </c-datepicker>
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-datepicker
                :editable="editable"
                label="검사증교부일자"
                name="inspCertificateIssuanceDate"
                v-model="equipInfo.inspCertificateIssuanceDate">
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-multi-select
                :editable="editable"
                codeGroupCd="REGULATION_BILL_CD"
                itemText="codeName"
                itemValue="code"
                label="관련 법규"
                name="relatedLaws"
                maxValues="3"
                v-model="equipInfo.relatedLaws">
              </c-multi-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-textarea
                :editable="editable"
                label="검사면제사유"
                :rows="3"
                name="reasonExemptionInspection"
                v-model="equipInfo.reasonExemptionInspection">
              </c-textarea>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hazardEquipmentInfo',
  props: {
    hhmHazardousMachineryId: {
      type: String,
      default: function() {
        return '';
      },
    },
    count: {
      type: Number,
      default: function() {
        return 0;
      },
    },
  },
  data() {
    return {
      editable: true,
      equipInfo: {
        hhmHazardousMachineryId: '',
        equipmentTypeCd: '',
        equipmentCd: '',
        equipmentTypeNm: '',
        equipmentName: '',
        plantCd: null,
        machineryClassification: null,
        itemNo: '',
        inspectionKindCd: null,
        manufacturer: '',
        emplacementPlace: '',
        emplacementMonth: '',
        demolitionMonth: '',
        passNumber: '',
        ewrNo: '',
        amount: '',
        esignBasisRatedLoad: '',
        esignBasisKind: '',
        inspCertificateIssuanceDate: '',
        lastInspectionDate: '',
        inspectionSubjectFlag: null,
        inspectionCycle: null,
        nextInspectionDate: '',
        reasonExemptionInspection: '',
        machineryName: '',
        gradeCd: null,
        departmentDeptCd: '',
        departmentUserId: '',
        relatedLaws: '',
        remark: '',
        regUserId: '',
        chgUserId: '',

        hazardEquipmentHistoryModels: [],
        equipmentImproveModels: [],
      },
      targetCdItems: [
        { code: "Y", codeName: "대상" },
        { code: "N", codeName: "비대상" },
      ], // 대상여부
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      saveUrl: 'transactionConfig.sop.hhm.hazard.check.insert.url',
      saveType: 'POST',
      isSave: false,
      checkUrl: '',
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
      updateMode: false,
      getUrl: '',
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.equipInfo.equipmentCd !== '';
    }
  },
  watch: {
    'equipInfo.inspectionSubjectFlag'() {
      if (!this.equipInfo.inspectionSubjectFlag || this.equipInfo.inspectionSubjectFlag === 'N') {
        this.equipInfo.inspectionKindCd = null;
        this.equipInfo.inspectionCycle = null;
      }
    },
    'count'() {
      this.getDetail();
    },
    'equipInfo'() {
      this.$emit('transInfo', this.equipInfo);
    },
  },
  methods: {
    init() {
      this.getUrl = selectConfig.sop.hhm.hazard.check.get.url;
      this.checkUrl = selectConfig.sop.hhm.hazard.check.check.url;
      this.insertUrl = transactionConfig.sop.hhm.hazard.check.insert.url;
      this.updateUrl = transactionConfig.sop.hhm.hazard.check.update.url;

      this.getDetail();
    },
    getDetail() {
      if (this.hhmHazardousMachineryId) {
        this.$http.url = this.$format(this.getUrl, this.hhmHazardousMachineryId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          // 빈값들 filter
          this.equipInfo = this.$_.clone(_result.data);
          this.updateMode = true;
        },);
      }
    },
    saveInfo() {
      if (this.hhmHazardousMachineryId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
            this.$http.url = this.$format(this.checkUrl, this.equipInfo.plantCd, this.equipInfo.itemNo, this.equipInfo.hhmHazardousMachineryId ? this.equipInfo.hhmHazardousMachineryId : 'TEMP');
            this.$http.type = 'GET';
            this.$http.request((_result) => {
              if (_result.data > 0) {
                window.getApp.$emit('ALERT', {
                  title: '안내', // 안내
                  message: '사업장 내 이미 존재하는 Item No입니다.', // 사업장 내 이미 존재하는 Item No입니다.
                  type: 'warning', // success / info / warning / error
                });
                return;
              } else {
                window.getApp.$emit('CONFIRM', {
                  title: '확인',
                  message: '저장하시겠습니까?',
                  // TODO : 필요시 추가하세요.
                  type: 'info', // success / info / warning / error
                  // 확인 callback 함수
                  confirmCallback: () => {
                    this.equipInfo.regUserId = this.$store.getters.user.userId;
                    this.equipInfo.chgUserId = this.$store.getters.user.userId;
                    this.isSave = !this.isSave;
                  },
                  // 취소 callback 함수
                  cancelCallback: () => {
                  },
                  });
                }
            },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    add() {
      // 초기에는 userId에 tempId를 부여
      this.popupOptions.title = '설비 검색'; // 설비 검색
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentPopup;
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.length > 0) {
          this.equipInfo.equipmentCd = data[0].equipmentCd;
          this.equipInfo.machineryName = data[0].equipmentName;
          this.equipInfo.amount = data[0].amount;

          // 설비 주기와 관련법규 연결하면 주석 풀어도 됨
          // this.equipInfo.inspectionCycleName= data[0].inspectionCycleName;
          // this.equipInfo.inspectionCycleCd= data[0].inspectionCycleCd;
          // this.equipInfo.recentInspectionDate= data[0].recentInspectionDate;
          this.equipInfo.relatedLaws= data[0].relatedLaws;
      }
    },
    remove() {
      this.equipInfo.equipmentCd = '';
      this.equipInfo.machineryName = '';
      this.equipInfo.amount = '';
    },
    saveCallback(equipInfo) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.hhmHazardousMachineryId = equipInfo.data;

      if (!this.updateMode) {
        this.$emit('changeStatus')
      }
      this.getDetail();
    },
  }
};
</script>